

.access-token {
  display: block;
  width: 100%; /* Adjust the width as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selection-card {
  width: 150px;
}
