@import '../../App.css';

.chat-panel-container {
    width: 600px;
    padding: 0px;
}

.chat-panel-container .card-body {
    padding: 0px;
    height: auto;
    overflow-y: auto;
}

.chat-panel-container .card-footer, .chat-panel-container .card-header {
    display: none;
} 

.customer-card-inner .arcault-avatar {
    height: 64px;
    width: 64px;
}

.customer-card-name-block {
    gap:var(--spacing-2);
    padding-bottom: var(--spacing-4);
}

.customer-card-title .h3 {
    padding-top: var(--spacing-1);
    line-height: var(--font-size-h3)
}

.customer-card-title .h2 {
    padding-top: 0px;
    line-height: var(--font-size-h2)
}

.customers-dashboard .chakra-skeleton {
    height: 209px;
    width: 298px;
}

